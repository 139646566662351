import {format, toZonedTime} from "date-fns-tz";


export const dasboardRoute = (userName: string, date: string) => {
  return `/dashboard/${userName}/date/${date}`
}

export const dasboardRouteToday = (userName: string, timeZone: string) => {
  // get today's date in the user's timezone in the format yyyy-MM-dd
  const now = new Date();
  const zonedDate = toZonedTime(now, timeZone);
  const todayInYYYYMMDD = format(zonedDate, 'yyyy-MM-dd', { timeZone: timeZone });
  return `/dashboard/${userName}/date/${todayInYYYYMMDD}`;
}